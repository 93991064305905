import React from "react";
import SEO from "components/seo";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";
import { graphql, Link } from "gatsby";
import styles from "./Homebrew.module.scss";
import FancyClassIcon from "components/ui/FancyClassIcon/FancyClassIcon";
import Avatar from "components/ui/avatar/Avatar";
import Img from "gatsby-image";

interface Props {
  data: any;
}
const HomebrewPage = ({ data }: Props) => {
  const {
    allMarkdownRemark: { edges },
    altClass: { edges: altClass },
    images: { edges: imgEdge },
  } = data;

  const cardBgImg = imgEdge.find((i: any) => i.node.base === "card-rune.png");
  const getImg = (src: string) => {
    const img = imgEdge.find((i: any) => {
      return (
        i.node.base === `${src.toLowerCase().replace(" ", "")}-portrait.jpg`
      );
    });
    return img ? img.node.childImageSharp.fluid : undefined;
  };

  return (
    <AppContentContainer spacing="extra">
      <SEO title="Homebrew" />
      <h1>Homebrew</h1>
      A list of homebrew classes that are allowed in our games.
      <h2 className={styles.sectionTitle}>Classes</h2>
      <div className={styles.classList}>
        {edges.map((e: any) => (
          <Link to={e.node.fields.slug} key={e.node.fields.slug}>
            <div className={styles.classCard}>
              {cardBgImg && (
                <div className={styles.cardBg}>
                  <Img fluid={cardBgImg.node.childImageSharp.fluid} />
                </div>
              )}

              <Avatar className={styles.avatar}>
                {getImg(e.node.frontmatter.title) && (
                  <Img fluid={getImg(e.node.frontmatter.title)} />
                )}
              </Avatar>
              <div className={styles.title}>
                <FancyClassIcon characterClass={e.node.frontmatter.title} />
                <h3>{e.node.frontmatter.title}</h3>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <h2 className={styles.sectionTitle}>Alternative Classes</h2>
      These are major updates/renovations to existing classes - often to the
      point where it's almost an entirely new class.
      <div className={styles.classList}>
        {altClass.map((e: any) => {
          const title = e.node.frontmatter.title.replace("Alt ", "");
          return (
            <Link to={e.node.fields.slug} key={e.node.fields.slug}>
              <div className={styles.classCard}>
                {cardBgImg && (
                  <div className={styles.cardBg}>
                    <Img fluid={cardBgImg.node.childImageSharp.fluid} />
                  </div>
                )}

                <Avatar className={styles.avatar}>
                  {getImg(title) && <Img fluid={getImg(title)} />}
                </Avatar>
                <div className={styles.title}>
                  <FancyClassIcon characterClass={title} />
                  <h3>{e.node.frontmatter.title}</h3>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </AppContentContainer>
  );
};
export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { category: { eq: "class" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            parent
            title
          }
          fields {
            slug
          }
        }
      }
    }
    altClass: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { category: { eq: "alt-class" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            parent
            title
          }
          fields {
            slug
          }
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "class-images" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
export default HomebrewPage;
