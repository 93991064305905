import React from "react";
import cx from "classnames";
import { ClassIcon, ClassIconBackground } from "components/ui/icons/ClassIcons";
import styles from "./FancyClassIcon.module.scss";

interface Props {
  characterClass: string;
  superFancy?: boolean;
  className?: string;
}

const FancyClassIcon = ({
  characterClass,
  className,
  superFancy = false,
}: Props) => (
  <div className={cx(styles.root, className, superFancy && styles.superFancy)}>
    <ClassIconBackground className={styles.bg} />
    <ClassIcon classIconName={characterClass} className={styles.icon} />
  </div>
);

export default FancyClassIcon;
